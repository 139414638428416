(function ($) {
  'use strict';

  $.extend(true, $.trumbowyg, {
    langs: {
      // jshint camelcase:false
      en: {
        fontsize: 'Font size',
        fontsizes: {
          'x-small': 'Extra small',
          'small': 'Small',
          'medium': 'Regular',
          'large': 'Large',
          'x-large': 'Extra large',
          'xx-large': 'XXL',
          'xxx-large': 'XXXL',
          'custom': 'Custom'
        },
        fontCustomSize: {
          title: 'Custom font size',
          label: 'Font size',
          value: '48px'
        }
      },
      it: {
        fontsize: 'Dimensione del carattere',
        fontsizes: {
          'x-small': 'Piccolissimo',
          'small': 'Piccolo',
          'medium': 'Normale',
          'large': 'Grande',
          'x-large': 'Extra grande',
          'xx-large': 'XXL',
          'xxx-large': 'XXXL',
          'custom': 'Personalizzato'
        },
        fontCustomSize: {
          title: 'dimensione del carattere personalizzato',
          label: 'Dimensione del carattere',
          value: '48px'
        }
      },
      da: {
        fontsize: 'Skriftstørrelse',
        fontsizes: {
          'x-small': 'Ekstra lille',
          'small': 'Lille',
          'medium': 'Normal',
          'large': 'Stor',
          'x-large': 'Ekstra stor',
          'xx-large': 'XXL',
          'xxx-large': 'XXXL',
          'custom': 'Brugerdefineret'
        },
        fontCustomSize: {
          title: 'Brugerdefineret skriftstørrelse',
          label: 'Skriftstørrelse',
          value: '48px'
        }
      },
      fr: {
        fontsize: 'Taille de la police',
        fontsizes: {
          'x-small': 'Très petit',
          'small': 'Petit',
          'medium': 'Normal',
          'large': 'Grand',
          'x-large': 'Très grand',
          'xx-large': 'XXL',
          'xxx-large': 'XXXL',
          'custom': 'Douane'
        },
        fontCustomSize: {
          title: 'Custom Font Size',
          label: 'Taille de police personnalisée',
          value: '48px'
        }
      },
      de: {
        fontsize: 'Schriftgröße',
        fontsizes: {
          'x-small': 'Sehr klein',
          'small': 'Klein',
          'medium': 'Normal',
          'large': 'Groß',
          'x-large': 'Sehr groß',
          'xx-large': 'XXL',
          'xxx-large': 'XXXL',
          'custom': 'Benutzerdefiniert'
        },
        fontCustomSize: {
          title: 'Benutzerdefinierte Schriftgröße',
          label: 'Font size',
          value: '48px'
        }
      },
      nl: {
        fontsize: 'Lettergrootte',
        fontsizes: {
          'x-small': 'Extra klein',
          'small': 'Klein',
          'medium': 'Normaal',
          'large': 'Groot',
          'x-large': 'Extra groot',
          'custom': 'Aangepaste'
        },
        fontCustomSize: {
          title: 'Aangepaste lettergrootte',
          label: 'Lettergrootte',
          value: '48px'
        }
      },
      tr: {
        fontsize: 'Yazı Boyutu',
        fontsizes: {
          'x-small': 'Çok Küçük',
          'small': 'Küçük',
          'medium': 'Normal',
          'large': 'Büyük',
          'x-large': 'Çok Büyük',
          'custom': 'Görenek'
        },
        fontCustomSize: {
          title: 'Özel yazı tipi boyutu',
          label: 'Yazı Boyutu',
          value: '48px'
        }
      },
      zh_tw: {
        fontsize: '字體大小',
        fontsizes: {
          'x-small': '最小',
          'small': '小',
          'medium': '中',
          'large': '大',
          'x-large': '最大',
          'custom': '自訂大小',
        },
        fontCustomSize: {
          title: '自訂義字體大小',
          label: '字體大小',
          value: '48px'
        }
      }
    }
  });
  // jshint camelcase:true

  // Add dropdown with font sizes
  $.extend(true, $.trumbowyg, {
    plugins: {
      fontsize: {
        init: function (trumbowyg) {
          trumbowyg.addBtnDef('fontsize', {
            dropdown: buildDropdown(trumbowyg)
          });
        }
      }
    }
  });

  function buildDropdown(trumbowyg) {
    const dropdown = [];
    const sizes = ['x-small', 'small', 'medium', 'large', 'x-large', 'xx-large'];

    $.each(sizes, function (index, size) {
      const previewSize = size === 'xxx-large' ? '48px' : size;
      trumbowyg.addBtnDef('fontsize_' + size, {
        text: '<span style="font-size: ' + previewSize + ';">' + trumbowyg.lang.fontsizes[size] + '</span>',
        hasIcon: false,
        fn: function () {
          trumbowyg.execCmd('fontSize', index + 1, true);
        }
      });
      dropdown.push('fontsize_' + size);
    });

    var freeSizeButtonName = 'fontsize_custom',
      freeSizeBtnDef = {
        fn: function () {
          trumbowyg.openModalInsert(trumbowyg.lang.fontCustomSize.title,
            {
              size: {
                label: trumbowyg.lang.fontCustomSize.label,
                value: trumbowyg.lang.fontCustomSize.value
              }
            },
            function (values) {
              var text = trumbowyg.range.startContainer.parentElement;
              var selectedText = trumbowyg.getRangeText();
              if ($(text).html() === selectedText) {
                $(text).css('font-size', values.size);
              } else {
                trumbowyg.range.deleteContents();
                var html = '<span style="font-size: ' + values.size + ';">' + selectedText + '</span>';
                var node = $(html)[0];
                trumbowyg.range.insertNode(node);
              }
              trumbowyg.saveRange();
              return true;
            }
          );
        },
        text: '<span style="font-size: medium;">' + trumbowyg.lang.fontsizes.custom + '</span>',
        hasIcon: false
      };
    trumbowyg.addBtnDef(freeSizeButtonName, freeSizeBtnDef);
    dropdown.push(freeSizeButtonName);

    return dropdown;
  }
})(jQuery);
